
import PortfolioContainer from './components/PortfolioContainer'


const App = () => {
  return (
    <div>
      <PortfolioContainer></PortfolioContainer>
    </div>
  )
}

export default App